"use client";

import { startHolyLoader } from "holy-loader";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import type React from "react";
import type { DependencyList, EffectCallback } from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import type { Breakpoint } from "shared/SharedTypes";
import { once } from "shared/SharedUtils";
import { BREAKPOINTS } from "src/components/common/Grid/GridConstants";
import { PRE_RENDERED_WIDTH_COOKIE_NAME, getFromParam } from "src/lib/ClientUtils";
import { getRequestStore } from "src/lib/RequestState";

export function useQueryParams() {
  const searchParams = useSearchParams();
  const router = useRouter();
  const pathname = usePathname();

  const createQueryString = useCallback(
    (name: string, value: string | null | undefined) => {
      const params = new URLSearchParams(searchParams);

      if (value === null || value === undefined) {
        params.delete(name);
      } else {
        params.set(name, value);
      }

      return params.toString();
    },
    [searchParams],
  );

  const setQueryParam = (
    queryName: string,
    value: string | null | undefined,
    options?: {
      replace?: boolean;
    },
  ) => {
    router[options?.replace ? "replace" : "push"](
      `${pathname}?${createQueryString(queryName, value)}`,
    );
  };

  return { queryParams: searchParams, createQueryString, setQueryParam };
}

const getBreakpoint = (width: number | undefined): Breakpoint | "unknown" => {
  if (width === undefined) {
    return "unknown";
  }
  return width > BREAKPOINTS.desktop.min ? "desktop" : "mobile";
};

const INITIAL_RENDER_CALLBACKS: Array<Function> = [];

export const useAfterInitialRender = (callback: () => void) => {
  INITIAL_RENDER_CALLBACKS.push(callback);
};

let INITIAL_RENDER_DONE = false;

export const isInitialRenderDone = () => {
  return INITIAL_RENDER_DONE;
};

export const setInitialRenderDone = () => {
  if (!INITIAL_RENDER_DONE) {
    INITIAL_RENDER_DONE = true;
    for (const cb of INITIAL_RENDER_CALLBACKS) {
      cb();
    }
  }
};

const getScreenWidth = (): number | undefined => {
  if (typeof window === "undefined") {
    const store = getRequestStore();
    if (!store) {
      return undefined;
    }

    const viewportWidth = store.headers.get("viewport-width");
    if (viewportWidth) {
      return Number.parseInt(viewportWidth);
    }
    return undefined;
  }

  // go with what the server rendered as the width to start with if we're on the client
  if (!INITIAL_RENDER_DONE) {
    const cookie = document.cookie
      .split("; ")
      .map((c) => c.split("="))
      .find(([name]) => name === PRE_RENDERED_WIDTH_COOKIE_NAME)
      ?.pop();
    return cookie ? Number.parseInt(cookie) : undefined;
  }

  return window.innerWidth;
};

export const useBreakpoint = (breakpoint: { min?: number; max?: number }): boolean => {
  const checkBreakpoint = useCallback(() => {
    const width = getScreenWidth();
    if (width === undefined) {
      return undefined;
    }
    return width >= (breakpoint.min ?? 0) && width <= (breakpoint.max ?? Number.MAX_SAFE_INTEGER);
  }, [breakpoint]);

  const [isBreakpoint, setIsBreakpoint] = useState<boolean>(() => checkBreakpoint() ?? false);

  const handleResize = useCallback(() => {
    const newIsBreakpoint = checkBreakpoint() ?? false;
    if (isBreakpoint !== newIsBreakpoint) {
      setIsBreakpoint(newIsBreakpoint);
    }
    return newIsBreakpoint;
  }, [checkBreakpoint, isBreakpoint]);

  useAfterInitialRender(() => {
    handleResize();
  });

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  return isBreakpoint;
};

export const useDeviceBreakpoint = (): Breakpoint | "unknown" => {
  const [breakpoint, setBreakpoint] = useState<Breakpoint | "unknown">(() => {
    return getBreakpoint(getScreenWidth());
  });

  const breakpointRef = useRef(breakpoint);
  breakpointRef.current = breakpoint;

  const handleResize = useCallback(() => {
    const newBreakpoint = getBreakpoint(getScreenWidth());
    if (breakpointRef.current !== newBreakpoint) {
      setBreakpoint(newBreakpoint);
    }
    return newBreakpoint;
  }, []);

  useAfterInitialRender(() => {
    handleResize();
  });

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  return breakpoint;
};

export const useDebouncedEffect = (effect: React.EffectCallback, deps: any[], delay: number) => {
  useEffect(() => {
    const handler = setTimeout(() => {
      effect();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, deps);
};

export const useDebouncedCallback = <T extends (...args: any[]) => any>(
  callback: T,
  deps: any[],
  delay: number,
): T & {
  cancel: () => void;
  flush: () => ReturnType<T> | undefined;
  immediate: T;
} => {
  const callbackRef = useRef(callback);
  const timeoutRef = useRef<any>();
  const argsRef = useRef<any[]>();

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  // @ts-ignore
  return Object.assign(
    useCallback((...args: Parameters<T>) => {
      argsRef.current = args;
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        callbackRef.current(...args);
      }, delay);
    }, deps),
    {
      flush: () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        if (argsRef.current) {
          return callbackRef.current(...argsRef.current);
        }
      },
      cancel: () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      },
      immediate: (...args: any[]) => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        callbackRef.current(...args);
      },
    },
  );
};

export const useForceUpdate = (): [() => void, number] => {
  const [state, setState] = useState(0);
  return [() => setState((s) => s + 1), state];
};

export const useThrottledCallback = <T extends (...args: any[]) => any>(
  callback: T,
  deps: any[],
  delay: number,
): T => {
  const callbackRef = useRef(callback);
  const timeoutRef = useRef<any>();

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  // @ts-ignore
  return useCallback((...args: Parameters<T>) => {
    if (!timeoutRef.current) {
      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = undefined;
      }, delay);
      callbackRef.current(...args);
    }
  }, deps);
};

export function useUpdateEffect(effect: EffectCallback, deps: DependencyList = []): void {
  const useFirstMountState = (): boolean => {
    const isFirst = useRef(true);

    if (isFirst.current) {
      isFirst.current = false;
      return true;
    }

    return isFirst.current;
  };

  const isFirstMount = useFirstMountState();

  useEffect(
    once(() => {
      if (!isFirstMount) {
        return effect();
      }
    }),
    deps,
  );
}

export const useBack = () => {
  const [canGoBack, setCanGoBack] = useState(false);

  useEffect(() => {
    const handlePopState = () => {
      setCanGoBack(window.history.length > 1);
    };
    window.addEventListener("popstate", handlePopState);
    handlePopState();
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const router = useRouter();

  const { currentFromParam } = useFromParam();

  if (currentFromParam) {
    return {
      canGoBack: true,
      back: () => {
        startHolyLoader();
        router.push(currentFromParam);
      },
    };
  }

  return {
    canGoBack,
    back: () => {
      startHolyLoader();
      router.back();
    },
  };
};

export const useFromParam = () => {
  const { queryParams, setQueryParam } = useQueryParams();
  const rawFromParam = queryParams.get("f");
  let currentFromParam = null;
  if (rawFromParam?.startsWith("/")) {
    currentFromParam = atob(decodeURIComponent(rawFromParam));
  }

  return {
    currentFromParam: currentFromParam,
    getFromParam: getFromParam,
    setFromParam: (from: string) => {
      setQueryParam("f", getFromParam(from));
    },
  };
};

export const useResizeObserver = <T extends HTMLElement>(
  callback: ResizeObserverCallback,
): React.RefObject<T> => {
  const ref = useRef<T>(null);

  useEffect(() => {
    const observer = new ResizeObserver(callback);
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [callback]);

  return ref;
};
