import chroma from "chroma-js";
import { v4 as uuidV4, validate as uuidValidate } from "uuid";

export const color = chroma;

export const uuid = (): string => {
  return uuidV4();
};

export const isUuid = (value: string): boolean => {
  return uuidValidate(value);
};

export const normalizeUrl = (url: string): string => {
  if (url.startsWith("https://")) {
    return url.replace("https://", "");
  }
  return url;
};

export const isDarkColor = (backgroundColor: string | undefined | null) => {
  if (!backgroundColor || backgroundColor === "transparent") {
    return false;
  }
  return color(backgroundColor).luminance() < 0.45;
};

export const hashCode = (s: string) => {
  let h = 0;
  const l = s.length;
  let i = 0;
  if (l > 0) while (i < l) h = ((h << 5) - h + s.charCodeAt(i++)) | 0;
  return h;
};

export const once = <F extends (...args: any[]) => any>(fn: F): F => {
  let result: ReturnType<F> | undefined = undefined;
  let called = false;
  return function (this: any, ...args: any[]) {
    if (!called) {
      called = true;
      return (result = fn.apply(this, args));
    }
    return result;
  } as F;
};
